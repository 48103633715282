<template>
    <div>
     <el-form
         :model="modelInfo"
         size="small"
         label-width="50px"
     >
        
     <el-row :gutter="22"  class="demo-row-inline">
       <el-col :span="4">
         <el-form-item label="列">
             <el-input-number
             style="width: 100px"
             v-model="row"
             placeholder="列"
             :min="0"
             @change="getRowArr"
             ></el-input-number>
         </el-form-item>
     </el-col>
     <el-col :span="4" >
         <el-form-item label="行">
             <el-input-number
             style="width: 100px"
             v-model="line"
             placeholder="行"
             :min="0"
             controls = false
             @change="getLineArr"
             ></el-input-number>
         </el-form-item>
     </el-col>
     <el-col :span="4">
       <!-- <el-button type="text" v-if="this.headerData.length > 0 && childHeader" @click="addHeader" size="small">增加子表头</el-button> -->
     </el-col>
       </el-row>
   
     </el-form>
     <el-table
     ref="singleTable"
     :data="tableData"
     border
     @current-change="handleCurrentChange"
     row-key="id"
     :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
     style="width: 100%"
     header-row-style="backgroundColor: #f5f7fa"
     header-cell-style="backgroundColor: #f5f7fa"
     >
     <el-table-column :key="index" :prop = "item.children&& item.children.length > 0 ? '' : item.key " v-for="(item,index) in headerData"
       width="200" >
       <template slot="header" class="tableColumn" slot-scope="headers"  >
         <el-input
             v-model="item.name"
             style="width: 100px"
             size="mini"
             placeholder="请输入"
         />
           <Tips style="display: inline-block"  :tipsObject="item" />
           <el-button type="text" @click="addHeaderChild(item)" size="small">增加子表头</el-button>
       </template>
      
       <template   slot-scope="scope" >
         <el-input
             v-model="scope.row[item.key].name"
             style="width: 100px"
             size="mini"
             placeholder="请输入"
         />
         <Tips style="display: inline-block"  :tipsObject="scope.row[item.key]" />
       </template>
       <el-table-column prop="ele.key" v-if="item.children.length>0"  :key="index" width="200"  v-for="(ele,index) in item.children">
         <template slot="header"  class="tableColumn" slot-scope="headers"  >
         <el-input
             v-model="ele.name"
             style="width: 100px"
             size="mini"
             placeholder="请输入"
         />
         <Tips style="display: inline-block"  :tipsObject="ele" />
 
       </template>
       <template   slot-scope="scope" >
         <el-input
             v-model="scope.row[ele.key].name"
             style="width: 100px"
             size="mini"
             placeholder="请输入"
         />
         <Tips style="display: inline-block"  :tipsObject="scope.row[ele.key]" />
         
       </template>
       </el-table-column>
     </el-table-column>
     <el-table-column
     v-if="headerData.length>0 && tableData.length > 0"
       label="操作"
       width="120">
       <template slot-scope="scope">
         <el-button type="text" @click="addRow(scope)" size="small">增加子行</el-button>
         <el-button type="text" @click="deleteRow(scope)" size="small">删除</el-button>
       </template>
     </el-table-column>
   </el-table>
  
    </div>
 </template>
     
 <script>
 import Tips from './tips.vue'
 
     export default {
       components: {
           Tips
         },
       props:{
         pData: {},
       },
       watch:{
         pData: {
       handler() {
         this.init()
       },
       immediate: true
     },
       },
       
    
       data() {
         return {
             line: 0,
             row: 0,
             tableData: [],
             headerData:[],
             tipVisible: false,
             clientTop: 0,
             clientLeft: 0,
             tipsObject: {},
             childHeader: true
         }
       },
       created(){
         this.init()
       },
       methods: {
         init(){
           if(JSON.stringify(this.pData) !== "{}"){
             this.headerData = this.pData.th;
             this.tableData = this.pData.td;
             this.row = this.pData.th.length;
             this.line = this.pData.td.length;
           }
         },
         getRowArr(){
             let keys = ['aa','ab','ac','ad','ae','af','ag','ah','aj','ak','al','am','an','ao','ap','aq','ar','as','at','au','av','aw','ax','ay','az','ba','bb','bc','bd','be','bf','bg','bh','bj','bk','bl','bm','bn','bo','bp','bq','br','bs','bt','bu','bv','bw','bx','by','bz']
             if(this.headerData.length < this.row){
               // let isChildren = this.headerData.length > 0 && this.headerData[0].children &&  this.headerData[0].children.length > 0 ? true :false
                 for(let i=this.headerData.length; i<this.row; i++){
                     this.headerData.push({
                         id: Math.floor(Math.random()*99999999999), //id
                         name: '',//名字
                         textStyle: '',//字段样式
                         isbt:'',//是否必填
                         isTips: '',//是否有说明
                         tips:'',//字段说明
                         sl:'',//填写示例
                         iszt: '',//是否来源中台
                         key:keys[i],//字段主键
                     })
                   if(this.tableData.length > 0){
                     this.tableData = this.rowGetTableData(this.tableData,keys[i])
                     }
                     
                 }
                 this.tableData = JSON.parse(JSON.stringify(this.tableData))
             }else{
                 this.headerData.length = this.row
                 this.tableData.forEach((ele)=>{
                   delete ele[this.row.keys]
                 })
                 this.tableData = JSON.parse(JSON.stringify(this.tableData))
             }
             
         },
         rowGetTableData(data = [],keys){
           data.forEach(ele=>{
             ele[keys] = {
                     id: Math.floor(Math.random()*99999999999), //id
                     name: '',//名字
                     textStyle: '',//字段样式
                     isbt:'',//是否必填
                     isTips: '',//是否有说明
                     tips:'',//字段说明
                     sl:'',//填写示例
                     iszt: '',//是否来源中台
                     key:'',//字段主键
                 }
             if(ele.children  && ele.children.length > 0 ){
                this.rowGetTableData(ele.children,keys)
             }
           })
           return data;
           // this.tableData.forEach((ele)=>{
           //               if(ele.children && ele.children.length > 0){
           //                 ele.children.forEach((element)=>{
           //                   if(element.children && element.children.length > 0){
           //                     element.children.forEach((em)=>{
           //                       em[keys[i]] = {
           //                           id: Math.floor(Math.random()*99999999999), //id
           //                           name: '',//名字
           //                           textStyle: '',//字段样式
           //                           isbt:'',//是否必填
           //                           isTips: '',//是否有说明
           //                           tips:'',//字段说明
           //                           sl:'',//填写示例
           //                           iszt: '',//是否来源中台
           //                           key:'',//字段主键
           //                       }
           //                     })
           //                   }
           //                   element[keys[i]] = {
           //                     id: Math.floor(Math.random()*99999999999), //id
           //                     name: '',//名字
           //                     textStyle: '',//字段样式
           //                     isbt:'',//是否必填
           //                     isTips: '',//是否有说明
           //                     tips:'',//字段说明
           //                     sl:'',//填写示例
           //                     iszt: '',//是否来源中台
           //                     key:'',//字段主键
           //                 }
           //                 })
                          
           //               }
           //               ele[keys[i]]= {
           //               id: Math.floor(Math.random()*99999999999), //id
           //               name: '',//名字
           //               textStyle: '',//字段样式
           //               isbt:'',//是否必填
           //               isTips: '',//是否有说明
           //               tips:'',//字段说明
           //               sl:'',//填写示例
           //               iszt: '',//是否来源中台
           //               key:'',//字段主键
           //           }
           //             })
         },
         getLineArr(){
             if(this.tableData.length < this.line){
                 for(let i=this.tableData.length;i<this.line;i++){
                 let obj = this.getObj();
                 this.tableData.push(obj)
             }
             }else{
                 
                 this.tableData.length = this.line
             }
             this.tableData = JSON.parse(JSON.stringify(this.tableData))
         },
         showTips (item){
         this.tipsObject = item
         },
         getObj(){
           let obj = {
                 id: Math.floor(Math.random()*99999999999),
             }
             for(let j=0;j<this.headerData.length;j++){
          let isChildren = this.headerData[j].children &&  this.headerData[j].children.length > 0 ? true :false
               if(isChildren){
                 this.headerData[j].children.forEach((item,h)=>{
                   obj[this.headerData[j].children[h].key] = {
                       id: Math.floor(Math.random()*99999999999), //id
                       name: '',//名字
                       textStyle: '',//字段样式
                       isbt:'',//是否必填
                       isTips: '',//是否有说明
                       tips:'',//字段说明
                       sl:'',//填写示例
                       iszt: '',//是否来源中台
                       key:'',//字段主键
                   }
                 })
                
               }else{
                 obj[this.headerData[j].key] = {
                 id: Math.floor(Math.random()*99999999999), //id
                 name: '',//名字
                 textStyle: '',//字段样式
                 isbt:'',//是否必填
                 isTips: '',//是否有说明
                 tips:'',//字段说明
                 sl:'',//填写示例
                 iszt: '',//是否来源中台
                 key:'',//字段主键
             }
               }
             }
           return obj
         },
        
         findTag (data, obj, allData){
           for(let i=0;i<allData.length;i++){
             if(allData[i].id == data.id){
               if (!allData[i].children) allData[i].children = [];
               allData[i].children.push(obj)
               return JSON.parse(JSON.stringify(allData));
             }else{
               let childrens = allData[i].children || [];
               const result = this.findTag(data,obj,childrens);
               if (result) {
                 return JSON.parse(JSON.stringify(allData));
               }
             }
           }
         },
         addRow(rows){
           let obj = this.getObj();
           this.tableData = this.findTag(rows.row,obj, this.tableData);
         },
         deleteRow(rows){
           this.tableData = this.delP(rows.row.id,this.tableData)
           console.log("this.tableData",this.tableData)
         },
       delP(id, allData) {
     for (let i = 0; i < allData.length;i++) {
         if (allData[i].id == id) {
             allData.splice(i,1)
             break;
         }
         if (allData[i].children) {
           allData[i].children = this.delP(id, allData[i].children)
             if (allData[i].children.length === 0) delete allData[i].children
         }
     }
     return  JSON.parse(JSON.stringify(allData));
 },
         // addHeader(){
         //   this.childHeader = false;
         //   for(let i=0; i<this.headerData.length;i++){
         //     let headerItem = JSON.parse(JSON.stringify(this.headerData[i]));
         //     this.headerData[i].key = this.headerData[i].key+"first"
         //     headerItem.id = Math.floor(Math.random()*99999999999)
         //     this.headerData[i].children = [{...headerItem}]
         //   }
         //   this.headerData = JSON.parse(JSON.stringify(this.headerData))
         // },
         addHeaderChild(row){
           let children = row.children && row.children.length > 0 ? row.children : [];
           let num = children.length
           children.push({
                         id: Math.floor(Math.random()*99999999999), //id
                         name: '',//名字
                         textStyle: '',//字段样式
                         isbt:'',//是否必填
                         isTips: '',//是否有说明
                         tips:'',//字段说明
                         sl:'',//填写示例
                         iszt: '',//是否来源中台
                         key:row.key+num,//字段主键
                   })
           for(let i=0; i<this.headerData.length;i++){
             if(this.headerData[i].id == row.id){
               this.headerData[i].children = children;
             }
           }
           this.headerData = JSON.parse(JSON.stringify(this.headerData))
           for(let i=0;i<this.tableData.length;i++){
              delete this.tableData[i][row.key]
              this.tableData[i][row.key+num]={
                 id: Math.floor(Math.random()*99999999999), //id
                 name: '',//名字
                 textStyle: '',//字段样式
                 isbt:'',//是否必填
                 isTips: '',//是否有说明
                 tips:'',//字段说明
                 sl:'',//填写示例
                 iszt: '',//是否来源中台
                 key:'',//字段主键
              }
             }
           this.tableData = JSON.parse(JSON.stringify(this.tableData))
           console.log("this.tableData",this.tableData)
         }
       }
       
     }
     </script>
     
     <style lang="scss" scoped>
     .tableColumn{
         display: flex;
         justify-content: space-between;
         .popover{
             width: 20px !important;
         }
     }
    
     </style>
     