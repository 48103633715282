<template>
    <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" top="5vh"
      width="70%" class="qmDialog selectPeopleByCurrentPrjoect" @close="closeDialog">
      <!-- <el-container> -->
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane label="组织&人员" name="organizationPersonnel">
              <el-container>
                <CommonTree
                    ref="commonTree"
                    treeTitle="项目架构"
                    :searchTitle="searchOrTitle"
                    :treeData="organizationPersonnelTreeData"
                    :isShowdig="false"
                    :defaultProps="organizationPersonnelDefaultProps"
                    :treeExpand="true"
                    @getNodeClick="organizationPersonnelTreeNodeClick"
                    node-key="id"
                    />
                <el-main>
                  <grid-layout
                      ref="gridLayOut"
                      :table-options="tableOption"
                      :table-data="organizationPersonnelTableData"
                      :table-loading="tableLoading"
                      @page-current-change="getPrjstructureuserList"
                      @page-size-change="getPrjstructureuserList"
                  >
                  <template #birthday="{ row }">
                      <span>
                          {{row.birthday && row.birthday != "" ? getAge(row.birthday) : ""}}
                      </span>
                  </template>
                  <template #customBtn="{ row }">
                      <el-button style="margin: 0 3px" type="text" size="small" @click="rowSelect(row)">选择</el-button>
                  </template>
                  </grid-layout>
                </el-main>
              </el-container>
            </el-tab-pane>
            <el-tab-pane label="相关方人员" name="thirdPartyPersonnel">
            <el-container>
                <CommonTree
                    ref="commonTreeUser"
                    treeTitle="相关方公司"
                    :searchTitle="searchOrThirdParty"
                    :treeData="userThirdPartyOrganizationTableData"
                    :defaultProps="thirdPartyPersonnelDefaultProps"
                    @getNodeClick="thirdPartyPersonnelTreeNodeClick"
                    :isShowdig="false"
                    :showCheckbox="false"
                    :treeExpand="false"
                    node-key="id"
                />
              <el-main>
                <grid-head-layout
                    ref="thirdPartyPersonnelGridHeadLayout"
                    :search-columns="corePersonnelSearchColumns"
                    @grid-head-search="thirdPartyPersonnelGridHeadSearch"
                    @grid-head-empty="thirdPartyPersonnelGridHeadEmpty"
                ></grid-head-layout>
                <grid-layout
                    ref="thirdGridLayOut"
                    :table-options="thirdPartyPersonnelTableOption"
                    :table-data="thirdPartyPersonnelTableData"
                    :table-loading="tableLoading"
                    @gird-handle-select-click="selectionChange"
                    class="itemListGridNoPage">
                  <template #birthday="{ row }">
                    <span :style="itemObject(row)">
                      {{ row.birthday && row.birthday != '' ? getAge(row.birthday) : '' }}
                    </span>
                  </template>
                  <template #customBtn="{ row }">
                    <el-button style="margin: 0 3px" type="text" size="small" @click="rowSelect(row)">选择</el-button>
                </template>
                </grid-layout>
              </el-main>
            </el-container>
          </el-tab-pane>
        </el-tabs>
      <!-- </el-container> -->
    </el-dialog>
  </template>

  <script>
  import CommonTree from "@/views/components/com_tree/index";
  import GridLayout from "@/views/components/layout/grid-layout";
  import { prjbasicinfoPage } from "@/api/safe/itemList";
  import {
    prjroleuserList,
    classificationRoleList,
    prjstructureList,
    prjstructureuserList,
    prjthirdcompanyPage,
    prjthirduserPage,
  } from "@/api/safe/itemList";
  import { classificationRoleGetList } from "@/api/system/role";
  import { calculateAge } from "@/util/util";
  import { mapGetters } from "vuex";
  export default {
    components: {
      CommonTree,
      GridLayout,
    },
    data() {
      return {
        showDialog: false,
        title: "",
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        tableOption: {
          selection: false,
          column: [
            {
              label: "姓名",
              prop: "userName",
              align: "center",
              overHidden: true,
            },
            {
              label: "性别",
              prop: "sex",
              width: 100,
              align: "center",
              dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl:
                "/api/sinoma-system/dict/dictionary?code=gender",
            },
            {
              label: "年龄",
              prop: "birthday",
              width: 100,
              align: "center",
              overHidden: true,
              slot: true,
            },
            {
              label: "组织名称",
              prop: "structureName",
              minWidth: 120,
              align: "center",
              overHidden: true,
              slot: false,
            },
            {
              label: "启用",
              prop: "isUsed",
              dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
              align: "center",
            },
          ],
        },
        tableData: [],
        prjId: "",
        prjName: "",
        activeName: "organizationPersonnel",
        // 组织人员
        searchOrTitle: "structureName",
        organizationPersonnelDefaultProps: {
          children: "children",
          label: "structureName",
        },
        organizationPersonnelTreeData: [],
        organizationPersonnelTableData: [],
        organizationPersonnelTreeNode: "",

        // 相关方
        searchOrThirdParty: 'companyName',
        userThirdPartyOrganizationTableData: [],
        thirdPartyPersonnelDefaultProps: {
            children: "children",
            label: "companyName",
        },
        thirdPartyPersonnelTreeNode: '',
        thirdPartyPersonnelTableOption: {
            index: true,
            indexLabel: "序号",
            menuWidth: 200,
            selection: false,
            column: [{
            label: "姓名",
            prop: "userName",
            align: "center",
            minWidth: 120,
            overHidden: true
            },
            {
            label: "性别",
            prop: "sex",
            minWidth: 80,
            overHidden: true,
            align: "center",
            dataType: 'number',
            props: {
                label: "dictValue",
                value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict/dictionary?code=gender",
            },
            {
            label: "年龄",
            prop: "birthday",
            minWidth: 80,
            align: "center",
            overHidden: true,
            slot: true,
            },
            {
            label: "联系电话",
            prop: "phone",
            align: "center",
            minWidth: 180,
            overHidden: true,
            },
            {
            label: "身份证",
            prop: "idCoding",
            minWidth: 185,
            align: "center",
            overHidden: true
            },
            {
            label: "籍贯",
            prop: "domicile",
            minWidth: 150,
            align: "left",
            overHidden: true
            },
            {
            label: "岗位",
            prop: "post",
            minWidth: 150,
            align: "center",
            overHidden: true
            },
            {
            label: "黑名单状态",
            prop: "inBlacklist",
            align: "center",
            minWidth: 95,
            formatter: (row, value, label) => {
                return row.inBlacklist ? '是' : '否'
            },
            overHidden: true
            },
            {
            label: "启用",
            prop: "isUsed",
            dataType: "number",
            minWidth: 80,
            props: {
                label: "dictValue",
                value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
            align: "center",
            },
            // {
            // label: "更新时间",
            // prop: "updateTime",
            // minWidth: 170,
            // align: "center",
            // overHidden: true
            // },
            ],
        },
        thirdPartyPersonnelTableData: [],
        corePersonnelSearchColumns: [
            {
            label: "姓名",
            prop: "userName",
            span: 4,
            placeholder: "请输入人员姓名",
            }
        ],
      };
    },
    computed: {
      ...mapGetters(["userInfo"]),
      itemObject(item){
        return (item)=>{
          try{
            let age = item.birthday && item.birthday != '' ? this.getAge(item.birthday) :0;
            if(age>=60){
              return {color:'red'};
            }else{
              return {color:'black'};
            }
          } catch {
            return {color:'black'};
          }
        }
      }
    },
    methods: {
      handleTabClick(tab) {
        if (tab.name == "organizationPersonnel") {
          this.organizationPersonnelTableData = [];
            this.getPrjstructureList();
            this.getPrjstructureuserList();
        } else if (tab.name == "thirdPartyPersonnel") {
            this.thirdPartyPersonnelTableData = []
            this.getPrjthirdcompanyPage()
            // this.getPrjthirduserPage()
            //   this.corePersonnelTableData = [];
        }
      },
      //项目组织架构
      getPrjstructureList() {
        prjstructureList({
          prjId: this.prjId,
        }).then((res) => {
          this.organizationPersonnelTreeData = [
            {
              id: "",
              structureName: this.prjName,
              children: [],
            },
          ];
          if ((res.data.code = 200 && res.data.data.length)) {
            this.organizationPersonnelTreeData = [
              {
                id: "",
                structureName: this.prjName,
                children: res.data.data,
              },
            ];

            this.$nextTick(() => {
              this.$refs.commonTree.$refs.commonTree.setCurrentKey(this.organizationPersonnelTreeData[0].id);
              this.organizationPersonnelTreeNodeClick(this.organizationPersonnelTreeData[0]);
            });
          }
        });
      },
      //项目组织架构人员
      getPrjstructureuserList() {
        this.tableLoading = true
        prjstructureuserList({
          prjId: this.prjId,
          structureId: this.organizationPersonnelTreeNode.id,
        }).then((res) => {
          this.organizationPersonnelTableData = res.data.data;
          this.tableData = res.data.data;
          this.tableLoading = false
        });
      },
      //项目组织架构树点击
      organizationPersonnelTreeNodeClick(node) {
        this.organizationPersonnelTreeNode = node;
        this.getPrjstructureuserList();
      },

      init(prjId, prjName) {
        this.showDialog = true;
        this.title = "选择人员";
        this.prjId = prjId;
        this.prjName = prjName;
        // this.getClassificationRoleList();
        // this.getPrjroleuserList();
        this.getPrjstructureList();
        this.getPrjstructureuserList();
      },
    //   // 搜索
    //   gridHeadSearch(searchForm) {
    //     this.page.currentPage = 1;
    //     this.searchForm = searchForm;
    //     this.onLoad(this.page, searchForm);
    //   },
    //   // 清空
    //   gridHeadEmpty(searchForm) {
    //     this.page.currentPage = 1;
    //     this.$refs.gridHeadLayout.searchForm = {};
    //     this.searchForm = {};
    //     this.onLoad(this.page, searchForm);
    //   },
    //   onLoad(page, params = {}) {
    //     this.page = page;
    //     this.tableLoading = true;

    //     prjbasicinfoPage(
    //       page.currentPage,
    //       page.pageSize,
    //       Object.assign(params, this.searchForm)
    //     ).then((res) => {
    //       const data = res.data.data;
    //       this.page.total = res.data.data.total;
    //       this.$refs.gridLayOut.page.total = data.total;
    //       this.tableData = data.records;
    //       this.tableLoading = false;
    //     });
    //   },
      //获取年龄
      getAge(data) {
        return calculateAge(data);
      },

    // 相关方
    // 相关方组织
    getPrjthirdcompanyPage(params = {}) {
        let obj = {
            prjId: this.prjId
        }
        prjthirdcompanyPage(Object.assign(params, obj)).then((res) => {
            let useData = []
            if (res.data.data.length) {
                res.data.data.map((item) => {
                    if (item.isUsed != 1) {
                        useData.push({
                        ...item
                        })
                    }
                })
                this.userThirdPartyOrganizationTableData = useData
                this.$nextTick(() => {
                this.$refs.commonTreeUser.$refs.commonTree.setCurrentKey(this.userThirdPartyOrganizationTableData[0].id)
                this.thirdPartyPersonnelTreeNodeClick(this.userThirdPartyOrganizationTableData[0])
                })
            }
            })
        },
        thirdPartyPersonnelTreeNodeClick(node) {
            this.thirdPartyPersonnelTreeNode = node
            this.getPrjthirduserPage()
        },
        // 相关方人员条件筛选
        // 搜索
        thirdPartyPersonnelGridHeadSearch(searchForm) {
            this.getPrjthirduserPage(searchForm);
        },
        // 清空
        thirdPartyPersonnelGridHeadEmpty(searchForm) {
            this.getPrjthirduserPage()
        },
        //项目相关方人员
        getPrjthirduserPage(params = {}) {
            if (this.thirdPartyPersonnelTreeNode != '') {
                if (JSON.stringify(params) == '{}') {
                  this.$refs.thirdPartyPersonnelGridHeadLayout.searchForm = {}
                }
                let obj = {
                  prjId: this.prjId,
                  companyId: this.thirdPartyPersonnelTreeNode.id
                }
                prjthirduserPage(Object.assign(params, obj)).then((res) => {
                  console.log(res)
                  this.thirdPartyPersonnelTableData = res.data.data
                })
            } else {
                this.thirdPartyPersonnelTableData = []
            }
        },
      // 选择项目
      rowSelect(row) {
        this.showDialog = false;
        this.$emit("callback", row);
      },
      closeDialog() {
        // this.$refs.gridHeadLayout.searchForm = {};
        this.searchForm = {};
        this.showDialog = false;
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  ::v-deep.selectPeopleByCurrentPrjoect .avue-crud .el-table {
    height: 55vh !important;
    max-height: 55vh !important;
  }
  ::v-deep .el-tree {
  // height: calc(100vh - 220px) !important;
  height: 60vh !important;
  max-height: 60vh !important;
  overflow: scroll;
}

::v-deep .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}

  </style>
