<template>
  <div>
    <el-container>
      <el-main>        
        <head-layout 
          head-title="安全技术交底"
          :head-btn-options="headBtnOptions"
          @head-goBack="headBack"
          @head-submit="headSubmit()"
        />
        <avue-form :option="formOptions" ref="formLayout" v-model="formData" style="padding:10px;" >
          <template slot="prjName">
            <el-input v-model="formData.prjName" disabled :title="formData.prjName"></el-input>
          </template>
          <template slot="areaName">
            <el-input v-model="formData.areaName" ref="areaName" @focus="changePrjArea" :disabled="type == 'view' || formType!=1"></el-input>
          </template>
        </avue-form>
        </el-main>
    </el-container>
    <el-container>
        <el-main>
            <uploader
                title="附件"
                @getTableData="handleUpload"
                :fileSize="500"
                accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls,.pptx,.xlsx"
                :multiple="true"
                :fileOptions="fileOptions"
                ref="uploader"
                :gridRowBtn="fileGridRowBtn"
                :disabled="type=='view' || formType>1"
            ></uploader>
        </el-main>
    </el-container>
    <el-container>
        <el-main>
          <head-layout  head-title="签字区" />
          <avue-form :option="signOption" ref="signLayout" v-model="formData" style="padding:10px;" >
            <template slot="handoverFiles">
              <div class="signBox">
                <div class="signImg">
                  <img :src="formData.handoverFiles" alt="">
                </div>
                <el-button v-if="formType==1 && type != 'view'" @click="signQrCode">扫码签字</el-button>
              </div>
            </template>
            <template slot="receiveFiles">
              <div class="signBox">
                <div class="signImg">
                  <img :src="formData.receiveFiles" alt="">
                </div>
                <el-button v-if="formType==2 && type != 'view'"  @click="signQrCode">扫码签字</el-button>
              </div>
            </template>
            <template slot="managerFiles">
              <div class="signBox">
                <div class="signImg">
                  <img :src="formData.managerFiles" alt="">
                </div>
                <el-button v-if="formType>2 && nodeData.managerFlg && type != 'view' && !managerFilesType"  @click="signQrCode">扫码签字</el-button>
              </div>
            </template>
          </avue-form>
        </el-main>
      </el-container>
      <el-container v-if="(formType>2 || formData.safetyFiles) && formData.receiveFiles">
        <el-main>
          <head-layout 
          head-title="执行情况"
        />
        <avue-form :option="safetyOption" ref="safetyLayout" v-model="formData" style="padding:10px;" >
          <template slot="safetyFiles">
            <div class="signBox">
              <div class="signImg">
                <img :src="formData.safetyFiles" alt="">
              </div>
              <el-button v-if="nodeData.safetyFlg && type != 'view' && !safetyFilesType" @click="signQrCode">扫码签字</el-button>
            </div>
          </template>
        </avue-form>
        </el-main>
      </el-container>
      <prjArea ref="prjArea" @callback="getArea" />
      <!-- <el-dialog title="被交底人选择" :visible.sync="userDialogVisible" width="80%" top="8vh" :before-close="handleCancel">
        <UserDetpDialog ref="UserDetpDialog" v-if="userDialogVisible" @select-data="selectData"/>
      </el-dialog> -->
      <prjPeople ref="prjPeople" @callback="selectData" />

      <!-- 二维码 -->
      <el-dialog
        :title="'扫描二维码'"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :visible.sync="showDialog"
        width="40%"
        :custom-class="'qrcodeDialog'"
        @opened="getQRimg"
      >
        <div style="text-align: center;">
          <div class="qrcode-img">
            <div id="qrcode" ref="qrCode"></div>
          </div>
          <p class="tip">打开移动端扫码签字</p>
        </div>
          
      </el-dialog>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from '@/views/components/layout/head-layout.vue'
import uploader from "@/components/file-upload/index";
import { mapGetters } from "vuex";
import {prjSafetyHandoverDetail, prjSafetyHandoverSubmit} from '@/api/prjSafetyHandover/index'
import prjArea from './component/prjArea.vue'
import { dateFormat } from "@/util/date";
import UserDetpDialog from '@/views/components/UserDeptDialog/UserDetpDialog';
import QRCode from 'qrcodejs2';
import prjPeople from "./component/prjPeople.vue";
import { getPageCode } from '@/api/system/serialNumber'
import {getUserInfo} from "@/api/system/user";

export default {
  components: {
    FormLayout,
    HeadLayout,
    uploader,
    prjArea,
    UserDetpDialog,
    prjPeople
  },

  data () {
    return {
      type: '',
      formType: '',
      formData: {
        hdCode: '',
        areaCode: '',
        areaId: '',
        areaName: '',
        handoverId: '',
        handoverUser: '',
        handoverFiles: '',
        prjId: '',
        prjName: '',
        files: ''
      },
      fileOptions: {
          linklabel:'name',
          column: [
          {
              label: "附件名",
              prop: "name",
              align: "left",
              overHidden: true,
          },
          {
              label: "附件格式",
              prop: "extension",
              align: "center",
              overHidden: true,
          },
          {
              label: "上传人",
              prop: "createUserName",
              align: "center",
              overHidden: true,
          },
          {
              label: "上传时间",
              prop: "createTime",
              align: "center",
              overHidden: true,
          },
          ],
      },
      fileGridRowBtn:[
          {
              label: '下载',
              emit: "row-download",
          },
          {
              label: '删除',
              emit: "row-del",
          },
      ],
      userDialogVisible: false,
      showDialog: false,
      nodeData: {},
      managerFilesType: false,
      safetyFilesType: false,
      userInfo: {},
    }
  },

  computed: {
    // ...mapGetters(['userInfo']),
    headBtnOptions(){
      let btnList = []
      if(this.type != 'view'){
        btnList.push({
          label: '确定',
          emit: 'head-submit',
          type: 'button',
        })
      }
      btnList.push({
          label: '返回',
          emit: 'head-goBack',
          type: 'button',
        })
      return btnList
    },
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
              label: "单据编号",
              prop: "hdCode",
              labelWidth: 120,
              span: 8,
              disabled:true,
          },
          {
              label: "项目名称",
              prop: "prjName",
              labelWidth: 120,
              span: 8,
              maxlength: 255,
              formslot: true,
          },
          {
              label: "施工部位或层次",
              prop: "areaName",
              labelWidth: 130,
              span: 8,
              formslot: true,
              rules: [
                {required: true, message: '请选择', trigger: 'change'}
              ]
          },
          {
              label: "交底日期",
              prop: "hdTime",
              labelWidth: 120,
              span: 8,
              type: "date",
              format: "yyyy-MM-dd", // 设置日期格式
              valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
              value: dateFormat(new Date, 'yyyy-MM-dd'),
              rules: [
                {required: true, message: '请选择', trigger: 'change'}
              ]
          },
          {
              label: "施工内容",
              prop: "buildContent",
              labelWidth: 120,
              span: 24,
              maxlength: 255,
              rules: [
                {required: true, message: '请填写', trigger: 'blur'}
              ]
          },
          {
              label: "交底项目",
              prop: "hdItem",
              labelWidth: 120,
              span: 24,
              maxlength: 255,
              rules: [
                {required: true, message: '请填写', trigger: 'blur'}
              ]
          },
          {
              label: "交底内容",
              prop: "hdContent",
              labelWidth: 120,
              span: 24,
              type: 'textarea',
              maxlength: 255,
              showWordLimit: true,
              rules: [
                {required: true, message: '请填写', trigger: 'blur'}
              ]
          },
        ]
      } 
    },

    signOption () {
      let column = [
        {
            label: "交底人",
            prop: "handoverFiles",
            labelWidth: 120,
            span: 8,
            formslot: true,
        },
      ]
      if(this.formType>1 || this.formData.receiveFiles){
        column.push(
          {
              label: "被交底人",
              prop: "receiveFiles",
              labelWidth: 120,
              span: 8,
              formslot: true,
          }
        )
      }
      if((this.formType>2 || this.formData.managerFiles) && this.formData.receiveFiles){
        column.push(
          {
            label: "项目经理",
              prop: "managerFiles",
              labelWidth: 120,
              span: 8,
              formslot: true,
          }
        )
      }
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: column
      }
    },

    safetyOption () {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
              label: "执行情况说明",
              prop: "remark",
              labelWidth: 120,
              span: 24,
              type: 'textarea',
              showWordLimit: true,
              maxlength: 255,
          },
          {
              label: "安全员",
              prop: "safetyFiles",
              labelWidth: 120,
              span: 8,
              formslot: true,
          },
        ]
      }
    }
  },

  created () {
    this.nodeData = JSON.parse(this.$route.query.row)
    this.type = this.$route.query.type

    getUserInfo().then((res) => {
      let data = res.data.data;
      console.log(data.realName)
      data.realName = JSON.parse(JSON.stringify(data.realName))
      this.userInfo = data
      if(this.nodeData.id){
        this.getDetail(this.nodeData.id)
      } else {
        this.formType = 1
        getPageCode({ruleCode: 'safety_hand_code'}).then(res=>{
          if(res.data.data){
                this.formData.hdCode = res.data.data
            }
        })
        this.formData.handoverId = this.userInfo.id
        this.formData.handoverUser = this.userInfo.realName.zh
        this.formData.handoverFiles = this.userInfo.electronicSignature
        this.formData.prjId = this.nodeData.prjId
        this.formData.prjName = this.nodeData.prjName
      }
    })
  },

  mounted () {
    if(this.type == 'view'){
      this.$refs.formLayout.allDisabled = true
    }
  },

  methods: {
    headBack () {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headSubmit () {
      // console.log(this.formData)
      this.$refs.formLayout.validate(valid => {
        if(valid){
          const {handoverFiles, receiveFiles, receiveId, managerFiles, safetyFiles,prjId,prjName} = this.formData
            // this.handleSave()
          if(!handoverFiles){
            this.$message.warning('请交底人签字')
          } else if(!receiveId || receiveId==-1){
            // this.userDialogVisible = true
            this.$refs.prjPeople.init(prjId,prjName)
          } else if (this.formType==2 &&!receiveFiles) {
            this.$message.warning('请签字')
          } else if (this.formType == 3 && !managerFiles && !safetyFiles) {
            this.$message.warning('请签字')
          } else {
            this.handleSave()
          }
          // prjSafetyHandoverSubmit(this.formData).then(res => {
          //   this.$refs.formLayout.$refs.form.allDisabled = false;
            
          // })
        }
      })
    },

    selectData(row, deptId, deptName) {
      // console.log(row)
      this.formData.receiveId = row.userId
      this.formData.receiveUser = row.userName
      this.formData.handoverTime = dateFormat(new Date, 'yyyy-MM-dd hh:mm:ss')
      this.handleCancel()
      this.handleSave()
    },

    handleSave () {
      this.formData.status = (this.formData.managerFiles && this.formData.safetyFiles) ? '2' : this.formData.receiveId&&this.formData.receiveId!=-1 ? '1': '0' 
      prjSafetyHandoverSubmit(this.formData).then(res => {
        // this.$refs.formLayout.$refs.form.allDisabled = false;
        this.headBack()
      })
    },

    handleCancel () {
      this.$refs.formLayout.$refs.form.allDisabled = false
      // this.$refs.safetyLayout.$refs.form.allDisabled = false
      this.userDialogVisible = false
    },

    handleUpload(data){
        this.formData.files = this.resetFileData(data)
    },
        //   组合附件数据
        resetFileData(data) {
            let arr = [];
            data.forEach((ele, ind) => {
                arr.push({
                createTime: ele.createTime,
                createUser: ele.createUser,
                createUserName: ele.createUserName,
                extension: ele.extension,
                fileSize: ele.fileSize,
                id: ele.id,
                path: ele.path,
                schedule: ele.schedule,
                uid: ele.uid,
                name: ele.name,
                size: ele.size,
                });
            }); 
            return JSON.stringify(arr);
        },

        getDetail (id) {
          prjSafetyHandoverDetail(id).then(res=>{
            const data = res.data.data
            this.formData = data
            this.$refs.uploader.initTaleData(data.files)
            this.managerFilesType = Boolean(data.managerFiles)
            this.safetyFilesType = Boolean(data.safetyFiles)
            // console.log(123,this.managerFilesType, this.safetyFilesType)
            // if(data.status == 2) this.formType = 5
            this.formType = 5
            if(!data.receiveId || data.receiveId==-1){
              // 未指定被交底人
              this.formType = 1
              // this.formData.managerTime = dateFormat(new Date, 'yyyy-MM-dd hh:mm:ss')
            } else if (!data.receiveFiles&&data.receiveId){
              // 被交底人签字
              this.formType = 2
              if(this.type !='view'){
                this.formData.receiveFiles = this.userInfo.electronicSignature
                this.formData.receiveTime = dateFormat(new Date, 'yyyy-MM-dd hh:mm:ss')
              }
            } else {
              if (this.nodeData.managerFlg && !this.formData.managerFiles){
                // 是项目经理 且未签字
                this.formType = 3
                if(this.type !='view'){
                  this.formData.managerFiles = 	this.userInfo.electronicSignature
                  // this.formData.managerId = this.userInfo.user_id
                  // this.formData.managerUser = this.userInfo.real_name
                  this.formData.managerId = this.userInfo.id
                  this.formData.managerUser = this.userInfo.realName.zh
                  this.formData.managerTime = dateFormat(new Date, 'yyyy-MM-dd hh:mm:ss')
                }
              }
              if (this.nodeData.safetyFlg && !this.formData.safetyFiles){
                // 是安全员 且未签字
                this.formType = 4
                if(this.type !='view'){
                  this.formData.safetyFiles = 	this.userInfo.electronicSignature
                  // this.formData.safetyId = this.userInfo.user_id
                  // this.formData.safetyUser = this.userInfo.real_name
                  this.formData.safetyId = this.userInfo.id
                  this.formData.safetyUser = this.userInfo.realName.zh
                  this.formData.safetyTime = dateFormat(new Date, 'yyyy-MM-dd hh:mm:ss')
                }
              } else {
                this.$nextTick(()=>{
                this.$refs.safetyLayout.allDisabled = true
              })
              }
            }
            if(this.formType>1){
              this.$refs.formLayout.allDisabled = true
              this.$refs.uploader.option.menu = false
            }

            // if((this.formType>2 || this.formData.safetyFiles) && (this.type == 'view' || !this.nodeData.safetyFlg)){
            //   this.$nextTick(()=>{
            //     this.$refs.safetyLayout.allDisabled = true
            //   })
            // }
          })
        },

        changePrjArea () {
          this.$refs.areaName.blur()
          this.$refs.prjArea.init(this.formData.prjId)
        },

        getArea (row) {
          // console.log('区域',row)
          this.formData.areaCode = row.areaCode
          this.formData.areaId = row.id 
          this.formData.areaName = row.areaName
          // console.log(this.formData)
        },

        signQrCode () {
          if(!this.formData.id){
            this.$refs.formLayout.validate(valid => {
              if(valid){
                this.formData.status = (this.formData.managerFiles && this.formData.safetyFiles) ? '2' : this.formData.receiveId&&this.formData.receiveId!=-1 ? '1': '0' 
                prjSafetyHandoverSubmit(this.formData).then(res => {
                  this.formData.id = res.data.data.id
                  this.$refs.formLayout.$refs.form.allDisabled = false;
                  this.showDialog = true
                })
              }
            })
          } else {
            this.showDialog = true
          }

        },
        getQRimg() {
          const {managerFlg, safetyFlg } = this.nodeData
          this.$refs.qrCode.innerHTML = "";
          let qrcode = new QRCode(this.$refs.qrCode, {
            width: 260,
            height: 260,
            text: `/pages/other/prjSafetyHandover/form?id=${this.formData.id}&managerFlg=${managerFlg}&safetyFlg=${safetyFlg}&type=prjSafetyHandover`,
            correctLevel: QRCode.CorrectLevel.H
          })
          qrcode._el.title = ''
        },
  }
}
</script>

<style lang="scss" scoped>
.signBox{
  display: flex;
  align-items: center;
}
.signImg{
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;
  width: calc(100% - 100px);
  margin-right: 10px;
  text-align: center;
  img{
    max-height: 100px;
    max-width: 100%;
  }
}
.qrcode-img{
  width: 260px;
  margin: 0 auto;
}
</style>